.tooltip {
  opacity: 0;
  z-index: -9000;
  width: max-content;
  max-width: 500px;
  text-align: left;
  padding: 0rem 0.5rem;
  background: #ffffff;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .tooltip {
    max-width: 300px;
  }
}

.tooltip .close-button {
  width: 1rem;
  height: 1rem;
  position: absolute;
  background-color: transparent;
  top: 2px;
  right: 2px;
  font-weight: bold;
  font-size: larger;
  color: #313639;
  opacity: 50%;
  border: none;
  cursor: pointer;
  text-align: right;
}

.tooltip p,
.tooltip div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tooltip .lang {
  font-size: larger;
}

.tooltip .term {
  font-weight: bold;
}

.tooltip .imputed {
  font-style: italic;
  color: dimgray;
}

.tooltip .pos-line {
  white-space: normal;
}

.tooltip .ety-mode {
  font-style: italic;
}

.tooltip .ety-prep {
  font-size: smaller;
}

.tooltip .ety-term {
  font-weight: bold;
}

.tooltip .wiktionary-link {
  color: #313639;
  text-decoration: underline;
}
